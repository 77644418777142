* {
  box-sizing: border-box;
}

body {
  margin: 40px auto;
  max-width: 650px;
  line-height: 1.6;
  font-size: 18px;
  color: #212121;
  padding: 0 10px;
}

ul {
  padding: 0;
  list-style: none;
}

.inline-list-item {
  display: inline;
}

h1 {
  font-size: 2.4rem;
  font-family: sans-serif;
}

h3 {
  font-size: 1.35rem;
  font-family: sans-serif;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.active {
  font-style: italic;
  font-weight: 900;
}

section {
  margin: 18px 0;
  border-top: 1px solid #383838;
  border-bottom: 1px solid #383838;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

address {
  font-style: normal;
}
